import { createObjectFromArray } from "@/src/utils/utils";

export const API_URL = {
  SERVICES: `/api/services`,
  GALLERY: `/api/gallery`,
  CLIENTS: `/api/clients`,
  VERIFY_RECAPTCHA: `/api/verify-recaptcha`,
  CONTACT_US: `/api/contact-us`,
  GET_IP: `/api/get-ip`,
  CLOUDINARY_IMAGE: `/api/cloudinary-image`,
  GOOGLE_SEARCH_CONSOLE: `/api/gsc`,
  GOOGLE_ANALYTICS_4: `/api/ga4`,
};

export const SERVICES = ["Central Air-Conditioning Plants", "Air-Cooled Chillers", "Water-Cooled Chillers", "Ductable Air-Conditioners", "Cassette Air-Conditioners", "Cold Room & Cold Storage", "Compressor Repairs", "Refrigerated Air Driers", "Panel Air-Conditioners", "Window & Split Air-Conditioners"];

export const SERVICES_OBJECT = createObjectFromArray(SERVICES);

export const CONTACT_SUBJECT = ["General Inquiry", "Request a Quote", "Service Request", "Annual Maintenance Contract (AMC)", "Compressor Repair Request", "Chiller Installation or Service", "Central Air-Conditioning Maintenance", "Ductable Air-Conditioners Support", "Window or Split AC Maintenance", "Panel AC Service", "Air Dryer Support", "Commercial Air-Conditioning Needs", "Emergency Assistance (24x7 Support)", "Feedback or Suggestions", "Other"];

export const COMPANY_DETAILS = {
  name: "Indo Aircon Engineers",
  icon: "https://res.cloudinary.com/dzdcdfwww/image/upload/v1742064698/logo-4X4_jw42br.png",
  icon_grayscale: "https://res.cloudinary.com/dzdcdfwww/image/upload/e_grayscale/v1742064698/logo-4X4_jw42br.png",
  logo: "https://res.cloudinary.com/dzdcdfwww/image/upload/v1742064698/logo_oqvgog.png",
  logo_grayscale: "https://res.cloudinary.com/dzdcdfwww/image/upload/e_grayscale/v1742064698/logo_oqvgog.png",
  favicon: "https://res.cloudinary.com/dzdcdfwww/image/upload/v1742064698/favicon_flzagd.png",
  industry: "HVAC & Air Conditioning Solutions",
  gst_number: "09GQZPS2613Q1ZJ",
  pan_number: "GQZPS2613Q",
  established_year: 1991,
  services: SERVICES,
  bank: {
    name: "Kotak Mahindra Bank",
    account_number: "9445838872",
    ifsc: "KKBK0005328",
    branch: "Panchsheel Park, Sahibabad",
  },
  contact: {
    phone: ["+91 84477 08802", "+91 98101 25522"],
    email: ["indoairconengineers@gmail.com", "indo_aircon442@yahoo.com"],
    website: "https://indoairconengineers.com/",
    website_development: "https://indo-aircon-engineers.vercel.app/",
  },
  corporate_address: {
    street: "Shop No. 1, B-88, DLF Dilshad Extention-2",
    city: "Ghaziabad",
    state: "Uttar Pradesh",
    postalCode: "201005",
    country: "India",
    googleMapLink: "https://maps.app.goo.gl/dYnsBYdxdKYhxUvd7",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.821294821436!2d77.3283419756058!3d28.69499187563069!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb2b577bcc19%3A0xed0f88d8b22ef59c!2sIndo%20Aircon%20Engineer!5e0!3m2!1sen!2sin!4v1742234677318!5m2!1sen!2sin",
  },
  registered_address: {
    street: "J-602, Ajnara Integrity, Raj Nagar Extension",
    city: "Ghaziabad",
    state: "Uttar Pradesh",
    postalCode: "201017",
    country: "India",
    googleMapLink: "https://maps.app.goo.gl/ai98nHyB43P4HcCb9",
    googleMapEmbedLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6999.1326986951435!2d77.4135152935791!3d28.702615999999985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cf0e31d0f8835%3A0x7ab20a3467601444!2sAjnara%20Integrity!5e0!3m2!1sen!2sin!4v1742234608494!5m2!1sen!2sin",
  },
  socialMedia: {
    whatsapp: "https://wa.me/918447708802?text=Hi,%20I%20have%20a%20query",
    facebook: "https://www.facebook.com/Indo-Aircon-Engineers-100463789240631",
    instagram: "https://www.instagram.com/indoairconengineers",
    linkedin: "https://in.linkedin.com/in/ayush-sharma-629223195",
    twitter: "https://twitter.com/indoairconeng",
  },
  founders: [
    { name: "Mr. Madan Sharma", role: "Founder" },
    { name: "Mr. Ayush Sharma", role: "CEO" },
  ],
  statistics: [
    { name: "Happy Clients", value: 1200, iconClassName: "icofont-simple-smile", className: "" },
    { name: "Service Quality", value: 100, iconClassName: "icofont-tools", className: "prsnt" },
    { name: "Years of Experience", value: 30, iconClassName: "icofont-chart-bar-graph", className: "plus" },
    { name: "Client Satisfaction", value: 100, iconClassName: "icofont-business-man", className: "prsnt" },
  ],
  developer: {
    name: "Vipin Kumar",
    username: "@vipinwap07",
    credits: "Vipin Kumar (@vipinwap07)",
    email: "vipinwap07@gmail.com",
    website: "https://github.com/vipinwap07",
    github: "https://github.com/vipinwap07",
    linkedin: "https://www.linkedin.com/in/vipinwap07",
  },
};

const INDIAN_STATES = {
  UP: { state: "Uttar Pradesh", TIN: "09", code: "UP" },
  DL: { state: "Delhi", TIN: "07", code: "DL" },
  AN: { state: "Andaman and Nicobar Islands", TIN: "35", code: "AN" },
  AP: { state: "Andhra Pradesh", TIN: "37", code: "AP" },
  AR: { state: "Arunachal Pradesh", TIN: "12", code: "AR" },
  AS: { state: "Assam", TIN: "18", code: "AS" },
  BR: { state: "Bihar", TIN: "10", code: "BR" },
  CH: { state: "Chandigarh", TIN: "04", code: "CH" },
  CG: { state: "Chhattisgarh", TIN: "22", code: "CG" },
  DN: { state: "Dadra & Nagar Haveli and Daman & Diu", TIN: "26", code: "DN" },
  GA: { state: "Goa", TIN: "30", code: "GA" },
  GJ: { state: "Gujarat", TIN: "24", code: "GJ" },
  HR: { state: "Haryana", TIN: "06", code: "HR" },
  HP: { state: "Himachal Pradesh", TIN: "02", code: "HP" },
  JH: { state: "Jharkhand", TIN: "20", code: "JH" },
  JK: { state: "Jammu and Kashmir", TIN: "01", code: "JK" },
  KA: { state: "Karnataka", TIN: "29", code: "KA" },
  KL: { state: "Kerala", TIN: "32", code: "KL" },
  LA: { state: "Ladakh", TIN: "38", code: "LA" },
  LD: { state: "Lakshadweep Islands", TIN: "31", code: "LD" },
  MP: { state: "Madhya Pradesh", TIN: "23", code: "MP" },
  MH: { state: "Maharashtra", TIN: "27", code: "MH" },
  MN: { state: "Manipur", TIN: "14", code: "MN" },
  ML: { state: "Meghalaya", TIN: "17", code: "ML" },
  MZ: { state: "Mizoram", TIN: "15", code: "MZ" },
  NL: { state: "Nagaland", TIN: "13", code: "NL" },
  OD: { state: "Odisha", TIN: "21", code: "OD" },
  PB: { state: "Punjab", TIN: "03", code: "PB" },
  PY: { state: "Puducherry", TIN: "34", code: "PY" },
  RJ: { state: "Rajasthan", TIN: "08", code: "RJ" },
  SK: { state: "Sikkim", TIN: "11", code: "SK" },
  TN: { state: "Tamil Nadu", TIN: "33", code: "TN" },
  TS: { state: "Telangana", TIN: "36", code: "TS" },
  TR: { state: "Tripura", TIN: "16", code: "TR" },
  UK: { state: "Uttarakhand", TIN: "05", code: "UK" },
  WB: { state: "West Bengal", TIN: "19", code: "WB" },
};
