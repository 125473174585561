import QRCode from "qrcode";

export const generateQRCode = async (url) => {
  try {
    return await QRCode.toDataURL(url);
  } catch (error) {
    console.error("QR Code Generation Error:", error);
    return "";
  }
};

export const isMobileNumber = (elem, helperMsg) => {
  var alphaExp = /^([6-9]{1})([0-9]{9})$/;
  if (elem.value.length === 0 || elem.value.match(alphaExp)) return true;
  else {
    alert(helperMsg);
    elem.value = "";
    elem.focus();
    return false;
  }
};

export const inputNumberOnly = (e) => {
  regexValidator(e, (char) => {
    const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab", "Enter"];
    if (allowedKeys.includes(e.key)) {
      return true;
    }
    return /^[0-9]$/.test(char);
  });
};

export const regexValidator = (e, validationFunc) => {
  if (!validationFunc(e.key)) e.preventDefault();
};

export const getInitials = (name) => {
  if (!name) return "";
  const namesArray = name.trim().split(" ");
  const initials = namesArray
    .map((n) => n.charAt(0).toUpperCase())
    .slice(0, 2)
    .join("");
  return initials;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
};

export const getCldPublicId = (cloudinaryUrl) => {
  try {
    const url = new URL(cloudinaryUrl);
    const pathname = url.pathname;
    const parts = pathname.split("/");
    const uploadIndex = parts.indexOf("upload");
    if (uploadIndex === -1 || uploadIndex + 1 >= parts.length) throw new Error("Invalid Cloudinary URL");
    let publicId = parts[uploadIndex + 1];
    if (publicId.startsWith("v") && !isNaN(publicId.substring(1))) {
      publicId = parts[uploadIndex + 2];
    }
    publicId = publicId.replace(/\.[^/.]+$/, "");
    return publicId;
  } catch (error) {
    console.error("Error extracting public ID:", error.message);
    return null;
  }
};

export const isMobile = () => {
  return window.matchMedia("(max-width: 767px)").matches;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const sanitizeString = (string) =>
  string
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "_")
    .replace(/^_|_$/g, "");

export const createObjectFromArray = (arr) => {
  return arr.reduce((acc, item) => {
    acc[sanitizeString(item)] = item;
    return acc;
  }, {});
};

export const formatNumber = (number) => {
  if (!number) return 0;
  const [integerPart, decimalPart] = parseFloat(number.toString().split(",").join("")).toString().split(".");

  const isNegative = integerPart[0] === "-";
  const numString = isNegative ? integerPart.slice(1) : integerPart;

  const lastThree = numString.slice(-3);
  const remainingNumbers = numString.slice(0, -3);
  const formattedNumber = remainingNumbers ? remainingNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
  const formattedDecimal = decimalPart ? "." + decimalPart.slice(0, 2) : "";

  return (isNegative ? "-" : "") + formattedNumber + formattedDecimal;
};

export const calculateAmount = (billingDetails, taxRate) => {
  const totalAmountBeforeTax = billingDetails.reduce((total, item) => total + item.quantity * item.price, 0);
  const taxAmount = (totalAmountBeforeTax * taxRate) / 100;
  let totalAmountWithFraction = totalAmountBeforeTax + taxAmount;
  let fraction = totalAmountWithFraction.toFixed(2).split(".")[1];
  if (fraction < 50) return { totalAmountBeforeTax, taxAmount, grandTotal: Math.floor(totalAmountWithFraction), fraction, isAddition: false };
  return { totalAmountBeforeTax, taxAmount, grandTotal: Math.ceil(totalAmountWithFraction), fraction, isAddition: true };
};

export const numbersToWords = (num) => {
  const a = ["", "One ", "Two ", "Three ", "Four ", "Five ", "Six ", "Seven ", "Eight ", "Nine ", "Ten ", "Eleven ", "Twelve ", "Thirteen ", "Fourteen ", "Fifteen ", "Sixteen ", "Seventeen ", "Eighteen ", "Nineteen "];
  const b = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  if ((num = num.toString()).length > 9) return "overflow";
  let n = ("000000000" + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) return;
  var str = "";
  str += n[1] != 0 ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore " : "";
  str += n[2] != 0 ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh " : "";
  str += n[3] != 0 ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand " : "";
  str += n[4] != 0 ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred " : "";
  str += n[5] != 0 ? (str != "" ? "And " : "") + (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) + "Only " : "";
  return str;
};

export const getCountryDataByCode = (iso3) => {
  const countryData = {
    AFG: { iso2: "AF", name: "Afghanistan" },
    ALB: { iso2: "AL", name: "Albania" },
    DZA: { iso2: "DZ", name: "Algeria" },
    AND: { iso2: "AD", name: "Andorra" },
    AGO: { iso2: "AO", name: "Angola" },
    AIA: { iso2: "AI", name: "Anguilla" },
    ATG: { iso2: "AG", name: "Antigua and Barbuda" },
    ARG: { iso2: "AR", name: "Argentina" },
    ARM: { iso2: "AM", name: "Armenia" },
    ABW: { iso2: "AW", name: "Aruba" },
    AUS: { iso2: "AU", name: "Australia" },
    AUT: { iso2: "AT", name: "Austria" },
    AZE: { iso2: "AZ", name: "Azerbaijan" },
    BHS: { iso2: "BS", name: "Bahamas" },
    BHR: { iso2: "BH", name: "Bahrain" },
    BGD: { iso2: "BD", name: "Bangladesh" },
    BRB: { iso2: "BB", name: "Barbados" },
    BLR: { iso2: "BY", name: "Belarus" },
    BEL: { iso2: "BE", name: "Belgium" },
    BLZ: { iso2: "BZ", name: "Belize" },
    BEN: { iso2: "BJ", name: "Benin" },
    BMU: { iso2: "BM", name: "Bermuda" },
    BTN: { iso2: "BT", name: "Bhutan" },
    BOL: { iso2: "BO", name: "Bolivia" },
    BIH: { iso2: "BA", name: "Bosnia and Herzegovina" },
    BWA: { iso2: "BW", name: "Botswana" },
    BRA: { iso2: "BR", name: "Brazil" },
    BRN: { iso2: "BN", name: "Brunei Darussalam" },
    BGR: { iso2: "BG", name: "Bulgaria" },
    BFA: { iso2: "BF", name: "Burkina Faso" },
    BDI: { iso2: "BI", name: "Burundi" },
    CPV: { iso2: "CV", name: "Cabo Verde" },
    KHM: { iso2: "KH", name: "Cambodia" },
    CMR: { iso2: "CM", name: "Cameroon" },
    CAN: { iso2: "CA", name: "Canada" },
    CYM: { iso2: "KY", name: "Cayman Islands" },
    CAF: { iso2: "CF", name: "Central African Republic" },
    TCD: { iso2: "TD", name: "Chad" },
    CHL: { iso2: "CL", name: "Chile" },
    CHN: { iso2: "CN", name: "China" },
    COL: { iso2: "CO", name: "Colombia" },
    COM: { iso2: "KM", name: "Comoros" },
    COG: { iso2: "CG", name: "Congo" },
    COD: { iso2: "CD", name: "Democratic Republic of the Congo" },
    CRI: { iso2: "CR", name: "Costa Rica" },
    CIV: { iso2: "CI", name: "Côte d'Ivoire" },
    HRV: { iso2: "HR", name: "Croatia" },
    CUB: { iso2: "CU", name: "Cuba" },
    CYP: { iso2: "CY", name: "Cyprus" },
    CZE: { iso2: "CZ", name: "Czechia" },
    DNK: { iso2: "DK", name: "Denmark" },
    DJI: { iso2: "DJ", name: "Djibouti" },
    DMA: { iso2: "DM", name: "Dominica" },
    DOM: { iso2: "DO", name: "Dominican Republic" },
    ECU: { iso2: "EC", name: "Ecuador" },
    EGY: { iso2: "EG", name: "Egypt" },
    SLV: { iso2: "SV", name: "El Salvador" },
    GNQ: { iso2: "GQ", name: "Equatorial Guinea" },
    ERI: { iso2: "ER", name: "Eritrea" },
    EST: { iso2: "EE", name: "Estonia" },
    SWZ: { iso2: "SZ", name: "Eswatini" },
    ETH: { iso2: "ET", name: "Ethiopia" },
    FJI: { iso2: "FJ", name: "Fiji" },
    FIN: { iso2: "FI", name: "Finland" },
    FRA: { iso2: "FR", name: "France" },
    GAB: { iso2: "GA", name: "Gabon" },
    GMB: { iso2: "GM", name: "Gambia" },
    GEO: { iso2: "GE", name: "Georgia" },
    DEU: { iso2: "DE", name: "Germany" },
    GHA: { iso2: "GH", name: "Ghana" },
    GRD: { iso2: "GD", name: "Grenada" },
    GLP: { iso2: "GP", name: "Guadeloupe" },
    GUM: { iso2: "GU", name: "Guam" },
    GTM: { iso2: "GT", name: "Guatemala" },
    GIN: { iso2: "GN", name: "Guinea" },
    GNB: { iso2: "GW", name: "Guinea-Bissau" },
    GUY: { iso2: "GY", name: "Guyana" },
    HND: { iso2: "HN", name: "Honduras" },
    HKG: { iso2: "HK", name: "Hong Kong" },
    HUN: { iso2: "HU", name: "Hungary" },
    ISL: { iso2: "IS", name: "Iceland" },
    IND: { iso2: "IN", name: "India" },
    IDN: { iso2: "ID", name: "Indonesia" },
    IRN: { iso2: "IR", name: "Iran" },
    IRQ: { iso2: "IQ", name: "Iraq" },
    IRL: { iso2: "IE", name: "Ireland" },
    ISR: { iso2: "IL", name: "Israel" },
    ITA: { iso2: "IT", name: "Italy" },
    JAM: { iso2: "JM", name: "Jamaica" },
    JPN: { iso2: "JP", name: "Japan" },
    JOR: { iso2: "JO", name: "Jordan" },
    KAZ: { iso2: "KZ", name: "Kazakhstan" },
    KEN: { iso2: "KE", name: "Kenya" },
    KWT: { iso2: "KW", name: "Kuwait" },
    KGZ: { iso2: "KG", name: "Kyrgyzstan" },
    LAO: { iso2: "LA", name: "Laos" },
    LVA: { iso2: "LV", name: "Latvia" },
    LBN: { iso2: "LB", name: "Lebanon" },
    LSO: { iso2: "LS", name: "Lesotho" },
    LBR: { iso2: "LR", name: "Liberia" },
    LBY: { iso2: "LY", name: "Libya" },
    LIE: { iso2: "LI", name: "Liechtenstein" },
    LTU: { iso2: "LT", name: "Lithuania" },
    LUX: { iso2: "LU", name: "Luxembourg" },
    MAC: { iso2: "MO", name: "Macau" },
    MDG: { iso2: "MG", name: "Madagascar" },
    MWI: { iso2: "MW", name: "Malawi" },
    MYS: { iso2: "MY", name: "Malaysia" },
    MDV: { iso2: "MV", name: "Maldives" },
    MLI: { iso2: "ML", name: "Mali" },
    MLT: { iso2: "MT", name: "Malta" },
    MHL: { iso2: "MH", name: "Marshall Islands" },
    MTQ: { iso2: "MQ", name: "Martinique" },
    MRT: { iso2: "MR", name: "Mauritania" },
    MUS: { iso2: "MU", name: "Mauritius" },
    MEX: { iso2: "MX", name: "Mexico" },
    MDA: { iso2: "MD", name: "Moldova" },
    MCO: { iso2: "MC", name: "Monaco" },
    MNG: { iso2: "MN", name: "Mongolia" },
    MNE: { iso2: "ME", name: "Montenegro" },
    MSR: { iso2: "MS", name: "Montserrat" },
    MAR: { iso2: "MA", name: "Morocco" },
    MOZ: { iso2: "MZ", name: "Mozambique" },
    MMR: { iso2: "MM", name: "Myanmar" },
    NAM: { iso2: "NA", name: "Namibia" },
    NRU: { iso2: "NR", name: "Nauru" },
    NPL: { iso2: "NP", name: "Nepal" },
    NLD: { iso2: "NL", name: "Netherlands" },
    NCL: { iso2: "NC", name: "New Caledonia" },
    NZL: { iso2: "NZ", name: "New Zealand" },
    NIC: { iso2: "NI", name: "Nicaragua" },
    NER: { iso2: "NE", name: "Niger" },
    NGA: { iso2: "NG", name: "Nigeria" },
    NIU: { iso2: "NU", name: "Niue" },
    NFK: { iso2: "NF", name: "Norfolk Island" },
    PRK: { iso2: "KP", name: "North Korea" },
    MKD: { iso2: "MK", name: "North Macedonia" },
    NOR: { iso2: "NO", name: "Norway" },
    OMN: { iso2: "OM", name: "Oman" },
    PAK: { iso2: "PK", name: "Pakistan" },
    PLW: { iso2: "PW", name: "Palau" },
    PSE: { iso2: "PS", name: "Palestine" },
    PAN: { iso2: "PA", name: "Panama" },
    PNG: { iso2: "PG", name: "Papua New Guinea" },
    PRY: { iso2: "PY", name: "Paraguay" },
    PER: { iso2: "PE", name: "Peru" },
    PHL: { iso2: "PH", name: "Philippines" },
    POL: { iso2: "PL", name: "Poland" },
    PRT: { iso2: "PT", name: "Portugal" },
    PRI: { iso2: "PR", name: "Puerto Rico" },
    QAT: { iso2: "QA", name: "Qatar" },
    REU: { iso2: "RE", name: "Réunion" },
    ROU: { iso2: "RO", name: "Romania" },
    RUS: { iso2: "RU", name: "Russia" },
    RWA: { iso2: "RW", name: "Rwanda" },
    KNA: { iso2: "KN", name: "Saint Kitts and Nevis" },
    LCA: { iso2: "LC", name: "Saint Lucia" },
    MAF: { iso2: "MF", name: "Saint Martin" },
    WSM: { iso2: "WS", name: "Samoa" },
    SMR: { iso2: "SM", name: "San Marino" },
    STP: { iso2: "ST", name: "São Tomé and Príncipe" },
    SAU: { iso2: "SA", name: "Saudi Arabia" },
    SEN: { iso2: "SN", name: "Senegal" },
    SRB: { iso2: "RS", name: "Serbia" },
    SYC: { iso2: "SC", name: "Seychelles" },
    SGP: { iso2: "SG", name: "Singapore" },
    SXM: { iso2: "SX", name: "Sint Maarten" },
    SVK: { iso2: "SK", name: "Slovakia" },
    SVN: { iso2: "SI", name: "Slovenia" },
    SLB: { iso2: "SB", name: "Solomon Islands" },
    SOM: { iso2: "SO", name: "Somalia" },
    ZAF: { iso2: "ZA", name: "South Africa" },
    KOR: { iso2: "KR", name: "South Korea" },
    SSD: { iso2: "SS", name: "South Sudan" },
    ESP: { iso2: "ES", name: "Spain" },
    LKA: { iso2: "LK", name: "Sri Lanka" },
    SUR: { iso2: "SR", name: "Suriname" },
    SWE: { iso2: "SE", name: "Sweden" },
    CHE: { iso2: "CH", name: "Switzerland" },
    SYR: { iso2: "SY", name: "Syria" },
    TWN: { iso2: "TW", name: "Taiwan" },
    TJK: { iso2: "TJ", name: "Tajikistan" },
    TZA: { iso2: "TZ", name: "Tanzania" },
    THA: { iso2: "TH", name: "Thailand" },
    TLS: { iso2: "TL", name: "Timor-Leste" },
    TGO: { iso2: "TG", name: "Togo" },
    TKL: { iso2: "TK", name: "Tokelau" },
    TON: { iso2: "TO", name: "Tonga" },
    TTO: { iso2: "TT", name: "Trinidad and Tobago" },
    TUN: { iso2: "TN", name: "Tunisia" },
    TUR: { iso2: "TR", name: "Turkey" },
    TCA: { iso2: "TC", name: "Turks and Caicos Islands" },
    TUV: { iso2: "TV", name: "Tuvalu" },
    UGA: { iso2: "UG", name: "Uganda" },
    UKR: { iso2: "UA", name: "Ukraine" },
    ARE: { iso2: "AE", name: "United Arab Emirates" },
    GBR: { iso2: "GB", name: "United Kingdom" },
    USA: { iso2: "US", name: "United States" },
    URY: { iso2: "UY", name: "Uruguay" },
    UZB: { iso2: "UZ", name: "Uzbekistan" },
    VUT: { iso2: "VU", name: "Vanuatu" },
    VAT: { iso2: "VA", name: "Vatican City" },
    VEN: { iso2: "VE", name: "Venezuela" },
    VNM: { iso2: "VN", name: "Vietnam" },
    VGB: { iso2: "VG", name: "Virgin Islands (British)" },
    WLF: { iso2: "WF", name: "Wallis and Futuna" },
    YEM: { iso2: "YE", name: "Yemen" },
    ZMB: { iso2: "ZM", name: "Zambia" },
    ZWE: { iso2: "ZW", name: "Zimbabwe" },
  };

  const upperIso3 = iso3.toUpperCase();
  return countryData[upperIso3] || { iso2: null, name: "Unknown Country" };
};
